import React from 'react';

function Publications() {
    return (
      <main>
        <h1>Publications</h1>
      </main>
    );
  }

  export default Publications;