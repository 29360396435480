import React from 'react';

function Home() {
    return (
      <main>
        <h1>Welcome to the Home Page</h1>
        <p>This is the home page of the website.</p>
      </main>
    );
  }

  export default Home;