import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../styles/LoginSignup.css';

function Signup() {
  // State to handle form inputs
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      navigate('/Profile');
    }
  }, [navigate]);



   // Handle form submission
   const handleSubmit = async (event) => {
    event.preventDefault();

        // Validation to check all fields are filled
        if (!firstName || !lastName || !email || !password) {
          setError('All fields are required');
          return;
        }
    
        try {
          setLoading(true);
          const response = await fetch('/.netlify/functions/createUserFunction', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ firstName, lastName, email, password }),
          });

          const result = await response;
          setLoading(false);

          if (response.ok) {
            setSuccess('User created successfully!');
            setError('');
            navigate('/Login');
            setLoading(false);
          } else {
            setError(result.message || 'Failed to create user');
            setLoading(false);
          }
        } catch (error) {
          setError('Error occurred: ' + error.message)
          setLoading(false);
        }
      };
  return (
    <div>
      {loading && (
        <div className='loading-overlay'>
          <div className="loader"></div>  
        </div>
      )}
    <div className="container">
    <div className="form-container">
      <h2>Sign Up</h2>

      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}

      <form>
        <div className="form-group">
          <label htmlFor="firstName">First name</label>
          <input 
            type="text" 
            id="firstName" 
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Last name</label>
          <input 
            type="text" 
            id="lastName" 
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input 
            type="email" 
            id="email" 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input 
            type="password" 
            id="password" 
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            />
        </div>
          <div 
            type="submit" 
            className="btn1"
            onClick={handleSubmit}
            >Sign Up</div>
      </form>

      <p className="space">Already have an account?</p>
        <Link to="/Login" className='buttonTag'>Login</Link>
    </div>
  </div>
  </div>
  );
}

export default Signup;