// src/components/Header.js
import React from 'react';
import '../styles/Footer.css'; // Import CSS for styling

function Footer() {
  return (
    <footer>
        <p>&copy; 2024 Emil Rimer. All rights reserved.</p>
    </footer>
  );
}

export default Footer;