import './styles/App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './componenets/Header';
import Footer from './componenets/Footer';
import About from './pages/About'; 
import Home from './pages/Home'; 
import Publications from './pages/Publications'; 
import Projects from './pages/Projects'; 
import Signup from './pages/signup'; 
import Login from './pages/Login'; 
import Profile from './pages/profile'; 


function App() {
  return (
    <Router>
    <div className="App">
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Publications" element={<Publications />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/Profile" element={<Profile />} />
        </Routes>
      <Footer />
    </div>
    </Router>
  );
}


export default App;
