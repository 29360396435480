import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../styles/LoginSignup.css'; // Import CSS for styling

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/Profile');
    }
  }, [navigate]);


   // Handle form submission
   const handleSubmit = async (event) => {
    event.preventDefault();

        // Validation to check all fields are filled
        if (!email || !password) {
          setError('All fields are required');
          return;
        }
    
        try {
          setLoading(true);
          const response = await fetch(`/.netlify/functions/readUserFunction?email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`);
          setLoading(false);

          if (response.ok) {
            const data = await response.json();
            if(data){
              setSuccess("Successfully logged in.")
              localStorage.setItem('token', data.token);
              localStorage.setItem('user', JSON.stringify(data));
              navigate('/Profile');
              setLoading(false);
            }
            else{
              setError("No user found");
              setLoading(false);
            }
          }
        } catch (error) {
          setError('Error occurred: ' + error.message);
          setLoading(false);
        }
      };
  return (
    <div> 
      {loading && (
        <div className='loading-overlay'>
          <div className="loader"></div>  
        </div>
      )}
    <div className="container">
      <div className="form-container">
        <h2>Login</h2>

        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
        
        <form>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input 
              type="email" 
              id="email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)} 
              />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input 
              type="password" 
              id="password" 
              value={password}
              onChange={(e) => setPassword(e.target.value)} 
              />
          </div>
          <div 
            type="submit" 
            className="btn1"
            onClick={handleSubmit}>Login</div>
        </form>
        <p className="space">Don't have an account?</p>
        <Link to="/Signup" className='buttonTag'>Sign up</Link>
      </div>
    </div>
           
    </div>
  );
}

export default Login;