import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css'; 

function Header() {
  return (
    <header className="header">
      <nav className="nav-links">
      <div className="logo">Emil Rimer</div>
        <div>
            <Link to="/" className='buttonTag'>Home</Link>
            <Link to="/About" className='buttonTag'>About</Link>
            <Link to="/Publications" className='buttonTag'>Publications</Link>
            <Link to="/Projects" className='buttonTag'>Projects</Link>
            <Link to="/Login" className='buttonTag'>Login</Link>
        </div>
      </nav>
    </header>
  );
}

export default Header;