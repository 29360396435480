import React from 'react';

function Projects() {
    return (
      <main>
        <h1>Projects</h1>
      </main>
    );
  }

  export default Projects;