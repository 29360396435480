import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Profile() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        const userString = localStorage.getItem("user");
        if (userString) {
            const user = JSON.parse(userString);
            setFirstName(user.user.firstName);
        }
    });

    const logOut = async (event) => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/Login');
    }



  return (
    <section className="hero">
    <div className="hero-content">
      <h1>Welcome {firstName}</h1>
      <div className='buttonTag' onClick={logOut}>Log out</div>
    </div>
  </section>
  );
}

export default Profile;