import React from 'react';
import '../styles/About.css'; // Import CSS for styling

function About() {
  return (
    <section className="hero">
        <h1>This is me</h1>
        <p>Emil</p>
        <a href='/pdfs/Emil Rimer - CV - Engelsk.pdf' className="buttonTag">Download CV</a>
    </section>
  );
}

export default About;